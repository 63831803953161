import React from 'react'
import Pessoa1 from '../assets/imgs/pessoa-1.png'
import Pessoa2 from '../assets/imgs/pessoa-2.png'
import Pessoa3 from '../assets/imgs/pessoa-3.png'
import Aviso from '../assets/imgs/ico-8.png'

const BcxSectionE = () => {
  return (
    <section id="sectionE">
      <div className="container">
        <h2 className="title">
          Quem pode contar com
          <br /> o <strong>Distrito Criativo CREDI</strong>
        </h2>

        <div className="micInd rowCard">
          <div className="imagem">
            <img src={Pessoa1} alt="Rua do Distrito Criativo" />
          </div>

          <div className="texto">
            <h3>
              Microempreendedor
              <br />
              <strong>Individual</strong>
            </h3>
            <p>
              com receita bruta anual igual <br /> ou inferior a R$ 81 mil
            </p>
            <p>
              <strong>Limite de crédito:</strong>
              <br />
              R$ 20 mil em 36 parcelas (sem carência) de aproximadamente R$
              750,00
              <br />
              R$ 20 mil em 30 parcelas (06 meses de carência) de aproximadamente
              R$ 950,00
              <br />
              <strong>Custo da garantia:</strong>
              <br />
              R$ 1.324,80
            </p>
            <p></p>
          </div>
        </div>

        <div className="micEmp rowCard">
          <div className="imagem">
            <img src={Pessoa2} alt="Prédio antigo de Santa Maria" />
          </div>

          <div className="texto">
            <h3>
              <strong>Microempresa</strong>
            </h3>
            <p>
              com receita bruta anual igual <br /> ou inferior a R$ 360 mil
            </p>
            <p>
              <strong>Limite de crédito:</strong>
              <br />
              <br />
              R$ 80 mil em 36 parcelas (sem carência) de aproximadamente R$
              2.950,00
              <br />
              R$ 80 mil em 30 parcelas (06 meses de carência) de aproximadamente
              R$ 3.700,00
              <br />
              <strong>Custo da garantia:</strong>
              <br />
              R$ 5.299,20
            </p>
          </div>
        </div>

        <div className="peqEmp rowCard">
          <div className="imagem">
            <img src={Pessoa3} alt="Foto da fachada do Theatro Treze de Maio" />
          </div>

          <div className="texto">
            <h3>
              <strong>Pequenas</strong>
              <br />
              Empresas
            </h3>
            <p>
              Faturamento anual entre <br /> R$ 360 mil e R$ 4,8 milhões
            </p>
            <p>
              <strong>Limite de crédito:</strong>
              <br />
              <br />
              R$ 150 mil em 36 parcelas (sem carência) de aproximadamente R$
              5.450,00
              <br />
              R$ 150 mil em 30 parcelas (06 meses de carência) de
              aproximadamente R$ 6.850,00
              <br />
              <strong>Custo da garantia:</strong>
              <br />
              R$ 9.936,00
            </p>
          </div>
        </div>

        <div className="aviso">
          <div className="imgAviso">
            <img
              src={Aviso}
              alt="Icone, formato Balão, com Exclamação dentro"
            />
          </div>

          <div className="textoAviso">
            <p>
              • Crédito sujeito a análise de crédito. Parcelas simuladas sem
              IOF.
              <br />• Terão acesso a linha associados que apresentarem no mínimo
              01 ano de faturamento completo, declarados nos documentos
              indicados na documentação necessária/abaixo.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BcxSectionE
