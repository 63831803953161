let agencias = [
    {
        id: 4455,
        name: 'Dores',
        cooperativa: 5,
    },
    {
        id: 4457,
        name: 'Av. N. Sra Medianeira',
        cooperativa: 5,
    },
    {
        id: 4453,
        name: 'Praça Nova',
        cooperativa: 5,
    },
    {
        id: 4454,
        name: 'Parque Industrial',
        cooperativa: 5,
    },
    {
        id: 4452,
        name: 'Centro',
        cooperativa: 5,
    },
    {
        id: 4456,
        name: 'de Camobi',
        cooperativa: 5,
    },
    {
        id: 4458,
        name: 'Santa Maria',
        cooperativa: 9,
    },
]
    
    export {agencias}


