import React from 'react'
import logoCred from '../assets/imgs/logo.png'

const AcxHeader = () => {
  return (
    <section id="header">
      <div className="container">
        <div className="slogan">
          <img src={logoCred} alt="Logotipo Distrito Criativo" />
        </div>
      </div>
    </section>
  )
}

export default AcxHeader
