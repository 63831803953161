import React from 'react'
import Sebrae from '../assets/imgs/sebrae.png'

const BcxSectionD = () => {
  return (
    <section id="sectionD">
      <div className="container">
        <div className="header">
          <div className="titulo">
            <img src={Sebrae} alt="Logo da Sebrae" />
            <h2>
              Uma Parceria com <br />
              <strong>soluções para sua empresa</strong>
            </h2>
          </div>
          <div className="descricao">
            <p>
              O Sebrae está oferecendo 8 capacitações online GRATUITAS aos
              interessados.
            </p>
            <p>
              Mesmo sem efetivação do crédito, você pode ter acesso a
              ferramentas que te ajudam a gerenciar melhor seus negócios.
            </p>
            <p>
              <strong>Aproveite essa oportunidade!</strong>
            </p>
          </div>
        </div>

        <div className="content">
          <div className="col">
            <h3>Empreendedorismo – 2h</h3>
            <a
              className="btn"
              href="https://digital.sebraers.com.br/produtos/empreendedorismo/curso/zoom-no-cliente-como-criar-valor-no-mercado/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ZOOM no cliente: como criar valor no mercado
            </a>

            <h3>Mercado – 3h</h3>
            <a
              className="btn"
              href="https://digital.sebraers.com.br/produtos/mercado/curso/economia-colaborativa-a-tendencia-que-pode-transformar-seu-negocio/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Economia Colaborativa - A tendência que pode transformar seu
              negócio
            </a>

            <h3>Finanças – 12h</h3>
            <a
              className="btn"
              href="https://digital.sebraers.com.br/produtos/financas/curso/custos-industriais-e-preco-de-venda/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Custos industriais e preço de venda - 6h
            </a>
            <a
              className="btn"
              href="https://digital.sebraers.com.br/produtos/financas/curso/financas-empresariais-controlar-para-expandir/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Finanças empresariais: controlar para expandir - 6h
            </a>
          </div>

          <div className="col">
            <h3>Estratégia – 6h</h3>
            <a
              className="btn"
              href="https://digital.sebraers.com.br/produtos/estrategia/curso/planejamento-para-inovar/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Planejamento para inovar
            </a>

            <h3>Processos – 3h</h3>
            <a
              className="btn"
              href="https://digital.sebraers.com.br/produtos/processos/curso/decisao-para-resultados-game-empresarial/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Decisão para resultados - Game empresarial
            </a>

            <h3>Marketing e Vendas – 11h</h3>
            <a
              className="btn"
              href="https://digital.sebraers.com.br/produtos/marketing-e-vendas/curso/potencialize-suas-vendas/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Potencialize suas vendas - 3h
            </a>
            <a
              className="btn"
              href="https://digital.sebraers.com.br/produtos/marketing-e-vendas/curso/mei-dono-do-seu-negocio-marketing-seja-assertivo/"
              target="_blank"
              rel="noopener noreferrer"
            >
              MEI Dono do seu negócio. Marketing, seja assertivo! - 8h
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BcxSectionD
