import React from 'react'
import logoCred from '../assets/imgs/logo-w.png'
import logosB from '../assets/imgs/logosCredSantaMaria.png'

const BcxSectionA = () => {
  return (
    <section id="SectionA">
      <div className="container">
        <div className="text">
          <p>
            O Distrito Criativo CREDI é uma solução para auxiliar
            microempreendedores individuais, microempresas e empresas de pequeno
            porte que possuem sua sede no município de Santa Maria.
          </p>
          <p>
            O objetivo é facilitar o acesso ao crédito além de fornecer
            capacitações em gestão, vendas e marketing.
          </p>
          <p>
            Um ecossistema de inovação em crédito assistido, criado pela união
            de esforços entre a Prefeitura de Santa Maria, Sebrae, Sicredi,
            Unicred e RS Garanti.
          </p>
        </div>

        <div className="logos">
          <img
            className="credLogo"
            src={logoCred}
            alt="Logotipo Distrito Criativo"
          />
          <img
            className="parceiros"
            src={logosB}
            alt="Logotipos Sicredi, Sebrae, RS Garanti"
          />
        </div>
      </div>
    </section>
  )
}

export default BcxSectionA
